import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Login, LoginResponse } from './login';
import { environment } from '../../../environments/environment';
import { EnvironmentService } from '../../environment.service';
import { ExceptionHandlerService } from 'src/app/exception-handler.service';
import { loadStripe, Stripe } from '@stripe/stripe-js';
@Injectable({
  providedIn: 'root',
})
export class LoginService {
  redirectUrl: string;
  err = new LoginResponse();
  loginUrl;
  userData;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  timeZone: any;
  private stripe: Stripe | null = null;
  stripeKey: any;
  login(user: Login): Observable<LoginResponse> {
    this.err = {
      success: false,
      message: 'Something went wrong',
      user_data: null,
    };
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Basic ${btoa(user.username + ':' + user.password)}`,
      }),
    };
    return this.http
      .post<LoginResponse>(
        `${this.loginUrl}/dashboard/user/login`,
        user,
        this.httpOptions
      )
      .pipe(tap(), catchError(this.handleError('login', this.err)));
  }

  /* ------------- dont remove this code -------------
      login(user: Login): Observable<LoginResponse> {
        this.err = { success: false, message: 'Something went wrong',locale:'' ,user_data: null };
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': `Basic ${btoa(user.username + ':' + user.password)}`
            })
        };
        return this.http.post<LoginResponse>(`${this.loginUrl}/dashboard/user/login`, user, this.httpOptions).pipe(
            // tap(data =>  this.isLoggedIn = true),
            tap(),
            catchError(this.handleError('login', this.err))
        );
    } */

  getLanguage() {
    return 'fr';
  }

  logout(): Observable<any> {
    const userData = JSON.parse(localStorage.getItem('user'));
    const token = userData?.token ?? '';
    this.loginUrl = this.envService.read('apiUrl');
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.envService.read('Authorization'),
        'access-token': token,
      }),
    };
    return this.http
      .post<any>(`${this.loginUrl}/dashboard/user/logout`, '', this.httpOptions)
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  getToken() {
    return JSON.parse(localStorage.getItem('user')).token;
  }

  isLoggedIn(url: string): boolean {
    const dashboardPrefix =
      environment.dashboardPrefix === '/' ? '' : environment.dashboardPrefix;
    if (url === dashboardPrefix + '/login') {
      try {
        const userData = JSON.parse(localStorage.getItem('user'));
        return !userData && userData.user_name;
      } catch (e) {
        return true;
      }
    } else {
      const userData = JSON.parse(localStorage.getItem('user'));
      return !!userData && userData.user_name;
    }
  }

  constructor(
    private readonly http: HttpClient,
    private readonly envService: EnvironmentService,
    private readonly expService: ExceptionHandlerService
  ) {
    this.userData = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : {};
    const token = this.userData.token ? this.userData.token : '';
    this.loginUrl = this.envService.read('apiUrl');
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.envService.read('Authorization'),
        'access-token': token,
      }),
    };
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.stripeKey = environment.stripeKey;
  }

  resetPassword(args): Observable<any> {
    args.timeZone = this.timeZone;
    const token = this.userData.token ? this.userData.token : '';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.envService.read('Authorization'),
        'access-token': token,
      }),
    };
    return this.http
      .post<any>(
        `${this.loginUrl}/dashboard/password/update`,
        args,
        this.httpOptions
      )
      .pipe(tap(), catchError(this.handleError('reset', this.err)));
  }

  forgotPassword(args): Observable<any> {
    args.timeZone = this.timeZone;
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http
      .post<any>(
        `${this.loginUrl}/dashboard/forgotpassword`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.handleError(error);
          return of(error);
        })
      );
  }

  onLoadSetPassword(args: any) {
    args.timeZone = this.timeZone;
    return this.http.post<any>(`${this.loginUrl}/onloadsetpassword`, args).pipe(
      map((data) => {
        return data;
      })
    );
  }

  setPassword(args: any) {
    args.timeZone = this.timeZone;
    return this.http.post<any>(`${this.loginUrl}/setpassword`, args).pipe(
      map((data) => {
        return data;
      })
    );
  }

  licenseValidation(args): Observable<any> {
    this.loginUrl = this.envService.read('apiUrl');
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.envService.read('Authorization')
      }),
    };
    return this.http
      .post<any>(`${this.loginUrl}/channelOnboard/token/verify`, args, this.httpOptions)
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  userSignup(args): Observable<any> {
    this.loginUrl = this.envService.read('apiUrl');
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.envService.read('Authorization')
      }),
    };
    return this.http
      .post<any>(`${this.loginUrl}/channelOnboard/signUp`, args, this.httpOptions)
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      return of(result);
    };
  }
  sendGoogleToken(args): Observable<any> {
    args.timeZone = this.timeZone;
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http
      .post<any>(
        `${this.loginUrl}/verify-token`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.handleError(error);
          return of(error);
        })
      );
  }
  sendLinkedinToken(arg): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http
      .post<any>(
        `${this.loginUrl}/auth/linkedin/callback`,
        arg,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.handleError(error);
          return of(error);
        })
      );
  }
  getCountries(): Observable<any> {
    return this.http
    .get<any>(`${this.loginUrl}/country/list`, this.httpOptions)
    .pipe(
      tap(),
      catchError((error) => {
        this.expService.handleError(error);
        return of(error);
      })
    );
  }
  getPlanDetails(): Observable<any> {
    console.log('getPlanDetails')
      return this.http
        .get<any>(`${this.loginUrl}/dashboard/users/plans`, this.httpOptions)
        .pipe(
          tap(),
          catchError((error) => {
            this.expService.handleError(error);
            return of(error);
          })
        );
    }
    selfOnboardApi(arg): Observable<any> {
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      };
      return this.http
        .post<any>(
          `${this.loginUrl}/dashboard/selfOnboard`,
          arg,
          this.httpOptions
        )
        .pipe(
          tap(),
          catchError((error) => {
            this.handleError(error);
            return of(error);
          })
        );
    }
    createCheckoutSession(arg) {
      return this.http.post<any>(`${this.loginUrl}/create-checkout-session`, arg);
    }

    async redirectToCheckout(sessionId: string) {
      if (this.stripe) {
        const { error } = await this.stripe.redirectToCheckout({ sessionId });
        if (error) {
          console.error('Stripe Checkout Error:', error.message);
        }
      }
    }
    async initializeStripe() {
      if (!this.stripe) {
        this.stripe = await loadStripe(this.stripeKey);
      }
    }
    oboardSignup(arg) {
      this.loginUrl = this.envService.read('apiUrl');
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: this.envService.read('Authorization')
        }),
      };
      return this.http
        .post<any>(`${this.loginUrl}/selfonboard/signup`, arg, this.httpOptions)
        .pipe(
          tap(),
          catchError((error) => {
            this.expService.handleError(error);
            return of(error);
          })
        );
    }
  signUpEmailValidate(email) {
    this.loginUrl = this.envService.read('apiUrl');
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.envService.read('Authorization')
      }),
    };
    return this.http
      .post<any>(`${this.loginUrl}/send/signup/email`, { email }, this.httpOptions)
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  onLoadSignup(key) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.envService.read('Authorization'),
      }
      ),
      params: new HttpParams().set('key', key)
    };
    return this.http
      .get<any>(`${this.loginUrl}/signup/link/expiry`, httpOptions)
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
}
