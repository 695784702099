<div class="auth-block-body">
    <form [formGroup]="signUpForm" (ngSubmit)="onSubmit()" *ngIf="!choosePlan">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 login-input"
            style="padding: 0% 5%; display: grid; width: 500px;overflow-y: scroll;height: 370px;">
            <div class="form-group">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 login-input">
                    <label for="loginUserName" style="
                  color: #333333;
                  text-align: start;
                  width: inherit;
                  margin-bottom: 5px;
                  font-weight: 500;
                ">{{ "common.name" | translate }}</label>
                    <div class="inputDiv">
                        <input id="name" type="text" class="form-control" name="name" formControlName="name"
                            style="border-radius: 5px;color: #333333!important;"
                            i18n-placeholder="@@placeHolderUserName" placeholder="{{ 'common.name' | translate }}"
                            required />
                        <span class="loginError" *ngIf="submitted && f.name.errors?.['required']"
                            i18n="@@usernameRequired">
                            {{ "login.name_required" | translate }}
                        </span>
                    </div>

                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 login-input">
                    <label for="loginUserName" style="
                   color: #333333;
                   text-align: start;
                   width: inherit;
                   margin-bottom: 5px;
                   font-weight: 500;
                 ">{{ "login.compnay" | translate }}</label>
                    <div class="inputDiv">
                        <input id="companyName" type="text" class="form-control" name="companyName"
                            formControlName="companyName" style="border-radius: 5px;color: #333333!important;"
                            i18n-placeholder="@@placeHolderUserName" placeholder="{{ 'login.compnay' | translate }}"
                            required />
                        <span class="loginError" *ngIf="submitted && f.companyName.errors?.['required']"
                            i18n="@@usernameRequired">
                            {{ "selfService.company_required" | translate }}
                        </span>
                    </div>

                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 login-input">
                    <label for="loginUserName" style="
                   color: #333333;
                   text-align: start;
                   width: inherit;
                   margin-bottom: 5px;
                   font-weight: 500;
                 ">{{ "common.email" | translate }}</label>
                    <div class="inputDiv">
                        <input id="loginUserName" type="text" class="form-control" name="email" formControlName="email"
                            style="border-radius: 5px;color: #333333!important;"
                            i18n-placeholder="@@placeHolderUserName" placeholder="{{ 'common.email' | translate }}"
                            required/>
                        <span class="loginError" *ngIf="submitted && f.email.errors?.['required']"
                            i18n="@@usernameRequired">
                            {{ "login.email_required" | translate }}
                        </span>
                        <span class="loginError" *ngIf="submitted && f.email.errors?.['email']"
                        i18n="@@usernameRequired">
                        {{ "login.must_be_valid" | translate }}
                    </span>
                    </div>

                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 login-input">
                    <label for="loginUserName" style="
                   color: #333333;
                   text-align: start;
                   width: inherit;
                   margin-bottom: 5px;
                   font-weight: 500;
                 ">{{ "login.password" | translate }}</label>
                    <div class="inputDiv">
                        <div class="input-group-prepend" id="loginpassword">
                            <span class=""><em class="fas fa-lock"
                                    style="color: black; font-size: medium;left: 15px;top: 12px;position: relative;"></em></span>
                        </div>
                        <input id="password"
                        [type]="showPassword ? 'text' : 'password'"
                        class="form-control"
                        name="password"
                        formControlName="password"
                            style="padding-left: 10%;border-radius: 5px;color: #333333!important;"
                            i18n-placeholder="@@placeHolderUserName" placeholder="{{ 'login.password' | translate }}"
                            required />
                            <em class="fas" [ngClass]="showPassword ? 'fa-eye-slash' : 'fa-eye'" (click)="togglePassword()" (keydown)="togglePassword()"
                                style="position: absolute; right: 24px; top: 60%; transform: translateY(-50%); cursor: pointer; color: #333;"></em>
                            <em [popover]="infoPassword" [placement]="'right'" [container]="'body'" [outsideClick]="true" class="fas fa-info-circle infoSignup" triggers="click"></em>
                        <span class="loginError" *ngIf="submitted && f.password.errors?.['required']"
                            i18n="@@usernameRequired">
                            {{ "login.password_required" | translate }}
                        </span>
                        <span class="loginError" *ngIf="submitted && f.password.errors?.['invalidPassword']" i18n="@@usernameRequired">
                            {{'user_management.valid_password' | translate}}
                        </span>
                    </div>

                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding: 0px;">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 login-input" style="text-align: left;">
                        <label for="loginUserName" style="
                   color: #333333;
                   text-align: start;
                   width: inherit;
                   margin-bottom: 5px;
                   font-weight: 500;
                 ">{{ "engage.city" | translate }}</label>
                        <div class="inputDiv">
                            <input id="city" type="text" class="form-control" name="city" formControlName="city"
                                style="border-radius: 5px;color: #333333!important;"
                                i18n-placeholder="@@placeHolderUserName" placeholder="{{ 'engage.city' | translate }}"
                                required />
                            <span class="loginError" *ngIf="submitted && f.city.errors?.['required']"
                                i18n="@@usernameRequired">
                                {{ "selfService.city_required" | translate }}
                            </span>
                        </div>

                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 login-input" style="text-align: left;">
                        <label for="loginUserName" style="
                   color: #333333;
                   text-align: start;
                   width: inherit;
                   margin-bottom: 5px;
                   font-weight: 500;
                 ">{{ "engage.zip" | translate }}</label>
                        <div class="inputDiv">
                            <input id="zip" type="text" class="form-control" name="zip" formControlName="zip"
                                style="border-radius: 5px;color: #333333!important;"
                                i18n-placeholder="@@placeHolderUserName" placeholder="{{ 'engage.zip' | translate }}"
                                required />
                            <span class="loginError" *ngIf="submitted && f.zip.errors?.['required']"
                                i18n="@@usernameRequired">
                                {{ "selfService.zip_required" | translate }}
                            </span>
                        </div>

                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 login-input">
                    <label for="loginUserName" style="
                   color: #333333;
                   text-align: start;
                   width: inherit;
                   margin-bottom: 5px;
                   font-weight: 500;
                 ">{{ "engage.country" | translate }}</label>
                    <div class="inputDiv">
                        <ngx-select-dropdown class="filterStyle" style="text-align: start;" tabindex="0"
                            [multiple]="false" [config]="config" [options]="countriesList" name="fieldName"
                            ngDefaultControl formControlName="country"></ngx-select-dropdown>

                        <span class="loginError" *ngIf="submitted && f.country.errors?.['required']"
                            i18n="@@usernameRequired">
                            {{ "selfService.country_required" | translate }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="form-group" style="margin-bottom: 0px" id="loginforgotpassword">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 login-input">
                    <button id="next" class="btn-default btn-auth loginBtn" type="submit">
                        {{ "selfService.next" | translate }}
                    </button>
                </div>
            </div>
        </div>
    </form>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" class="planDivSub" style="display: grid; width: fit-content;"
        *ngIf="choosePlan">
        <div style="color: black;text-align: left;">
            <h4>Choose Your Plans</h4>
        </div>
        <div class="form-group planLabel">
            <div *ngFor="let item of planList" class="planDiv">
                <div style="min-height: 313px;">
                    <h5 style="font-weight: bold;">{{item.name}}</h5>
                    <h5>{{item.price}}</h5>
                    <div class="subTab" *ngIf="item.details?.mau"><em class="fa-solid fa-check"></em>
                        <span>{{item.details.mau}} MAU</span></div>
                    <div *ngFor="let tab of item.tabs">
                        <div class="subTab"><em class="fa-solid fa-check"></em>
                            <span *ngIf="tab?.name">{{tab.name}}</span>
                            <span *ngIf="tab?.info">{{tab.info}}</span>
                        </div>

                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 login-input"
                    style="text-align: center;margin-top: 10px;">
                    <button *ngIf="item.priceId" id="choosePlan" class="btn-default btn-auth loginBtn" type="submit"(click)="onPlanChoose(item)">
                        {{ "selfService.choosePlan" | translate }}
                    </button>
                    <button *ngIf="!item.priceId" id="choosePlan" class="btn-auth loginBtn contactUs" type="submit">
                        <a href="mailto:info@uib.ai?subject=Unified%20AI%20Premium%20Plan%20Inquiry"> {{ "selfService.contactUs" | translate }}</a>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
    <ng-template #infoPassword>
        <div class="card" style="border:none;max-height: 250px;">
          <div class="card-body" style="padding: 0px 0px 5px 0px !important;height:15%;">
            <ul style="font-size: 11px;font-style: italic;color: #828282;list-style-type:disc;padding: 6px 18px;">
                <li>{{'user_management.password_minimum_length' | translate}}</li>
                <li>{{'user_management.password_uppercase_letters' | translate}}</li>
            </ul>
          </div>
        </div>
        </ng-template>
