import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from 'rxjs';
import { EnvironmentService } from "src/app/environment.service";
import { ExceptionHandlerService } from "src/app/exception-handler.service";
import { SharedsService } from "src/app/shareds/shareds.service";
import { tap, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
  })
  export class WhatsappFlowsService {
    httpOptions: any;
    apiUrl: string;

    constructor(
      private readonly http: HttpClient,
      private readonly envService: EnvironmentService,
      private readonly sharedService: SharedsService,
      private readonly expService: ExceptionHandlerService
    ) {
      this.apiUrl = this.envService.read('apiUrl');
      this.setHttpHeaders();
    }

    setHttpHeaders() {
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'access-token': this.sharedService.token,
        }),
      };
    }

  fetchWhatsappFLows(arg): Observable<any> {
    this.httpOptions.params = new HttpParams()
      .set('appId', this.sharedService.appId)
      .set('limit', arg.limit)
      .set('skip', arg.skip)
      .set('search',arg.search);

    return this.http
      .get<any>(`${this.apiUrl}/whatsApp/template/flows`, this.httpOptions)
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  updateWhatsappFLows(args): Observable<any> {
    this.httpOptions.params = null;
    args.appId = this.sharedService.appId;
    args.timeZone = this.sharedService.timeZone;
    return this.http.post<any>(`${this.apiUrl}/update/whatsApp/template/flows`, args, this.httpOptions).pipe(
        tap(),
        catchError((error) => {
            this.expService.handleError(error);
            return of(error);
        })
    );
}
}
