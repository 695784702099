import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from 'rxjs';
import { EnvironmentService } from "src/app/environment.service";
import { ExceptionHandlerService } from "src/app/exception-handler.service";
import { SharedsService } from "src/app/shareds/shareds.service";
import { tap, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
  })
  export class FlowResponsesService {
    httpOptions: any;
    apiUrl: string;

    constructor(
      private http: HttpClient,
      private envService: EnvironmentService,
      private sharedService: SharedsService,
      private expService: ExceptionHandlerService
    ) {
      this.apiUrl = this.envService.read('apiUrl');
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: this.envService.read('Authorization'),
          'access-token': this.sharedService.token,
        }),
    }
    }
    

  apiFetchUibFlows(args): Observable<any> {
    if(args.flowtype) {
      this.httpOptions.params = new HttpParams()
      .set('appId', this.sharedService.appId)
      .set('search', args.search)
      .set('limit', args.limit)
      .set('skip', args.skip)
      .set('flowtype',args.flowtype);
    } else {
      this.httpOptions.params = new HttpParams()
      .set('appId', this.sharedService.appId)
      .set('search', args.search)
      .set('limit', args.limit)
      .set('skip', args.skip)
    }
        return this.http.get<any>(`${this.apiUrl}/flow/response/list`,this.httpOptions)
        .pipe(
        tap(),
         catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
    }

    apiFetchUibFlowsDetails(args): Observable<any> {
      this.httpOptions.params = new HttpParams()
      .set('appId', this.sharedService.appId)
        .set('search', args.search)
        .set('limit', args.limit)
        .set('skip', args.skip)
        .set('flowname',args.flowname)
        .set('flow_id',args.flow_id)
        return this.http.get<any>(`${this.apiUrl}/flow/response/details`,this.httpOptions)
        .pipe(
        tap(),
         catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
    }

    apidownloadFlows(args): Observable<any> {
      this.httpOptions.params = new HttpParams()
      .set('appId', this.sharedService.appId)
        .set('flowname',args.flowname)
        .set('flow_id',args.flow_id)
        .set('flowtype',args.flowtype)
      return this.http
        .post<any>(
          `${this.apiUrl}/flow/response/download`,
          args,
          this.httpOptions
        )
        .pipe(
          tap(),
          catchError((error) => {
            this.expService.handleError(error);
            return of(error);
          })
        );
    }
}
