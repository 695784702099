export const environment = {
    production: true,

    apiUrl: 'https://beta-smartcontact-api.unificationengine.com',
    authUrl: 'https://beta-oauth2.unificationengine.com',
    netatmoUrl: 'https://api.netatmo.com/api/getstationsdata',
    appKey: 'iotm6523457',
    appSecret: '',
    Authorization: 'EA86F5C615A287C5F93C65D4D4F33S5YmyE7A7D7EE3FA27529256A66EFAB9C1',
    sentryKey: 'https://a9af2cb77d124206ab8f01978637f2f6@o494445.ingest.sentry.io/5698011',
    environment: 'production',

    pageTitle: 'Unified AI',
    dashboardEnabled: true,
    dashboardPrefix: '/dashboard',
    dashboardRoute: 'dashboard',
    apiUrlAgm: window["env"]["apiUrlAgm"] || "default",
    stripeKey: window["env"]["stripeKey"] || 'pk_live_Bvq3hyR16AjFNOn3XFuGMtfh00W8IZssFX',
  // debug: window["env"]["debug"] || false
};
