<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="
    background-image: url('assets/images/dashboard/login.avif');
    height: 100vh;
  " id="mainDiv">
    <div *ngIf="isUserValid" class="auth-block center-div" style="
      text-align: center;
      background-color: white;
      border-radius: 10px;
      padding: 2%;width:fit-content;
    ">
        <div style="text-align: center; padding: 2% 0%">
          <img alt="" ng-src="assets/images/dashboard/unified-ai-logo-blue.avif" height="50" width="201"
            src="assets/images/dashboard/unified-ai-logo-blue.avif" />
        </div>
        <div *ngIf="isUserValid">
    </div>
</div>
