import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EnvironmentService } from 'src/app/environment.service';
import { Observable, of, Subject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { SharedsService } from 'src/app/shareds/shareds.service';
import { ExceptionHandlerService } from 'src/app/exception-handler.service';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  apiUrl: string;
  apiUrlUpload: string;
  httpOptions: any;
  userId: any;

  public allowedToPublic = new Subject<any>();
  allowedTopublicState = this.allowedToPublic.asObservable();

  allowedToPublicSwitchChange(flag) {
    this.allowedToPublic.next(flag);
  }

  allowedToPublicSwitchState(): Observable<any> {
    return this.allowedToPublic.asObservable();
  }

  constructor(
    private readonly http: HttpClient,
    private readonly envService: EnvironmentService,
    private readonly sharedService: SharedsService,
    private readonly expService: ExceptionHandlerService
  ) {
    const userData = JSON.parse(localStorage.getItem('user'));
    this.userId = userData?.user_id ?? '';
    this.apiUrl = this.envService.read('apiUrl');
    this.apiUrlUpload = this.envService.read('apiUrl');
    this.setHttpHeaders();
  }
  setHttpHeaders() {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.envService.read('Authorization'),
        'access-token': this.sharedService.token,
      }),
    };
  }
  menutab(): Observable<any> {
    const menuLang = localStorage.getItem('Language') || 'en';
    this.httpOptions.params = null;
    const args = {timeZone: this.sharedService.timeZone, lang: menuLang};
    return this.http
      .post<any>(`${this.apiUrl}/dashboard/menutabs/list`, args , this.httpOptions)
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  sendAppsettings(args): Observable<any> {
    return;
  }

  createQuestions(args): Observable<any> {
    this.httpOptions.params = null;
    args.appId = this.sharedService.appId;
    args.timeZone = this.sharedService.timeZone;
    return this.http
      .post<any>(
        `${this.apiUrl}/dashboard/settings/save/template`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  getQuestions(): Observable<any> {
    this.httpOptions.params = new HttpParams().set(
      'appId',
      this.sharedService.appId
    ).set('timeZone', this.sharedService.timeZone);
    return this.http
      .get<any>(
        `${this.apiUrl}/dashboard/settings/list/template`,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  getLanguages(): Observable<any> {
    this.httpOptions.params = new HttpParams().set(
      'appId',
      this.sharedService.appId
    ).set('timeZone', this.sharedService.timeZone);
    return this.http
      .get<any>(`${this.apiUrl}/list/languages`, this.httpOptions)
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  getTimeZone(): Observable<any> {
    this.httpOptions.params = new HttpParams().set(
      'appId',
      this.sharedService.appId
    ).set('timeZone', this.sharedService.timeZone);
    return this.http
      .get<any>(`${this.apiUrl}/list/timezones`, this.httpOptions)
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  getImageQuestions(): Observable<any> {
    this.httpOptions.params = new HttpParams()
      .set('appId', this.sharedService.appId)
      .set('type', 'image')
      .set('timeZone', this.sharedService.timeZone);
    return this.http
      .get<any>(
        `${this.apiUrl}/dashboard/settings/list/template`,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  getAppleFormQuestions(): Observable<any> {
    this.httpOptions.params = new HttpParams()
      .set('appId', this.sharedService.appId)
      .set('type', 'appleform')
      .set('timeZone', this.sharedService.timeZone);
    return this.http
      .get<any>(
        `${this.apiUrl}/dashboard/settings/list/template`,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  addAgentQuestions(): Observable<any> {
    this.httpOptions.params = null;
    const args = {
      appId: this.sharedService.appId,
      user_id: this.userId,
      timeZone: this.sharedService.timeZone
    };
    return this.http
      .post<any>(
        `${this.apiUrl}/add/agent/questions`,
        args,

        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  removeAgentQuestions(): Observable<any> {
    this.httpOptions.params = null;
    const args = {
      appId: this.sharedService.appId,
      timeZone: this.sharedService.timeZone
    };
    return this.http
      .post<any>(
        `${this.apiUrl}/remove/agent/questions`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  deleteQuestions(args): Observable<any> {
    this.httpOptions.params = null;
    args.appId = this.sharedService.appId;
    args.timeZone = this.sharedService.timeZone;
    return this.http
      .post<any>(
        `${this.apiUrl}/dashboard/settings/delete/template`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  updateQuestions(args): Observable<any> {
    this.httpOptions.params = null;
    args.appId = this.sharedService.appId;
    args.timeZone = this.sharedService.timeZone;
    return this.http
      .post<any>(
        `${this.apiUrl}/dashboard/settings/update/template`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  createApplications(args): Observable<any> {
    this.httpOptions.params = null;
    args.appId = this.sharedService.appId;
    args.timeZone = this.sharedService.timeZone;
    return this.http
      .post<any>(
        `${this.apiUrl}/dashboard/settings/save/iMessageApp`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  getApplications(): Observable<any> {
    this.httpOptions.params = new HttpParams().set(
      'appId',
      this.sharedService.appId
    ).set('timeZone', this.sharedService.timeZone);

    return this.http
      .get<any>(
        `${this.apiUrl}/dashboard/settings/list/iMessageApp`,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  deleteApplications(args): Observable<any> {
    this.httpOptions.params = null;
    args.appId = this.sharedService.appId;
    args.timeZone = this.sharedService.timeZone;
    return this.http
      .post<any>(
        `${this.apiUrl}/dashboard/settings/delete/iMessageApp`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  updateApplications(args): Observable<any> {
    this.httpOptions.params = null;
    args.appId = this.sharedService.appId;
    args.timeZone = this.sharedService.timeZone;
    return this.http
      .post<any>(
        `${this.apiUrl}/dashboard/settings/update/iMessageApp`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  addDPApplications(args): Observable<any> {
    this.httpOptions.params = null;
    args.appId = this.sharedService.appId;
    args.timeZone = this.sharedService.timeZone;
    return this.http
      .post<any>(
        `${this.apiUrl}/dashboard/set_app_channels`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  getTrailAccountDetails(): Observable<any> {
    this.httpOptions.params = new HttpParams().set(
      'appId',
      this.sharedService.appId
    ).set('timeZone', this.sharedService.timeZone);
    return this.http
      .get<any>(`${this.apiUrl}/getAppSettings`, this.httpOptions)
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);

        })
      );
  }
  fileupload(args): Observable<any> {
    this.httpOptions.params = null;
    return this.http
      .post<any>(
        `${this.apiUrl}/upload/attachment`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  connectChannels(args, action): Observable<any> {
    args.onboardType = action
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.envService.read('Authorization'),
        'access-token': JSON.parse(localStorage.getItem('access-token')),
      }),
    };
    return this.http
      .post<any>(
        `${this.apiUrl}/channelOnboard/addChannel`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  getChannelInfo(action): Observable<any> {
    const arg = {
      onboardType: action
    }
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.envService.read('Authorization'),
        'access-token': JSON.parse(localStorage.getItem('access-token')),
      }),
    };
    return this.http
      .post<any>(
        `${this.apiUrl}/channelOnboard/getChannels`,
        arg,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  update_channelDetails(args): Observable<any> {
    this.httpOptions.params = null;
    return this.http
      .post<any>(
        `${this.apiUrl}/update/channelDetails`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  getTokenForTwitter(args): Observable<any> {
    this.httpOptions.params = new HttpParams()
      .set('channel', args.channel)
      .set('developerId', args.developerId)
      .set('appId', args.appId)
      .set('userId', args.userId)
      .set('parentAppId', args.parentAppId);
    return this.http
      .get<any>(
        `${this.apiUrl}/twitter/fetch/oauth/token`,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  validateClientAPIURL(args): Observable<any> {
    this.httpOptions.params = new HttpParams()
      .set('api_url', args.clientApiUrl)
      .set('headerDetails', args.headerDetails)
      .set('appId', args.appId);
    return this.http
      .get<any>(
        `${this.apiUrl}/validate/clientUrl`,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }


  getFiles(data: any): Observable<any> {
    console.log('12345', data);
    return this.http
    .post<any>(`${this.apiUrlUpload}/dashboard/train/rag`, data , this.httpOptions);
  }


deleteRagDocument(args: any): Observable<any> {
  this.httpOptions.params = null;
  return this.http.post<any>(`${this.apiUrl}/v1/delete/ragDocument`, args,
      this.httpOptions).pipe(
          tap(),
          catchError((error) => {
              this.expService.handleError(error);
              return of(error);
      })
  );
}
getWabaLocalStorages(): Observable<any> {
  return this.http
    .get<any>(
      `${this.apiUrl}/waba/localstorages`,
      this.httpOptions
    )
    .pipe(
      tap(),
      catchError((error) => {
        return of(error);
      })
    );
}
getCRMVariableList(): Observable<any> {
  return this.http
    .get<any>(
      `${this.apiUrl}/hubspot/contact/properties`,
      this.httpOptions
    )
    .pipe(
      tap(),
      catchError((error) => {
        return of(error);
      })
    );
}
getPlanSettings(planId): Observable<any> {
  this.httpOptions.params = new HttpParams()
  .set('planId', planId)
    return this.http
      .get<any>(`${this.apiUrl}/dashboard/users/plansSettings`, this.httpOptions)
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  closeApplication(subscriptionId) {
    this.httpOptions.params = new HttpParams()
    .set('subscriptionId', subscriptionId)
    .set('appId',this.sharedService.appId)
    .set('userId', this.userId)
    .set('active_status',false);
    return this.http
      .get<any>(
        `${this.apiUrl}/application/disable`,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  addNewChannels(args, action): Observable<any> {
    args.onboardType = action
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.envService.read('Authorization'),
        'access-token': JSON.parse(localStorage.getItem('access-token')),
      }),
    };
    return this.http
      .post<any>(
        `${this.apiUrl}/create/new/application`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
}
