import { Component, HostListener, Injector, OnInit } from '@angular/core';
import { FlowResponsesService } from './flow-responses.service';
import { TranslateService } from '@ngx-translate/core';
import { SharedsService } from 'src/app/shareds/shareds.service';
import { MatDialog } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import alasql from 'alasql';

@Component({
  selector: 'app-flow-responses',
  templateUrl: './flow-responses.component.html',
  styleUrls: ['./flow-responses.component.scss']
})

export class FlowResponsesComponent implements OnInit {
  actionHeader = 'WhatsApp Flow';
  editActionHeader = 'Update';
  objectKeys = Object.keys;
  searchOne: any;
  translatorLanguage: any;
  sharedService: SharedsService;
  displayedColumns: string[] = ['flow_name', 'flow_type', 'created_on','Flowresponsecount'];
  displayedColumnsUib: string[] = ['flow_name', 'flow_type','username','user_identifier','modified_on'];
  dataOne: any = [];
  flowListAll : any = [];
  dataUib: any =[];
  selecteUser: any;
  totalFlows:any;
  pagelist = 1;
  paginationDisabled = false;
  parent = false;
  dataSourceWhatsappFlow:any = [];
  dataSourceUibFlow:any = [];
  listType: any;
  goBack = false;
  showDownloadbutton = true;
  selectedUser: any ; 
  flowInfo:string = "all";
  selectedFlowName: any;
  searchQuery: string = '';
  flowNames: any[] = [];
  dropdownConfig: any = { displayKey: 'flow_name', height: '200px', placeholder: 'Select Flow', search: true };
  loaderShow:boolean;
  detailedDataTable:any =[];
  onflowresponsecount =0;
  onflowcountall = 0;
  showParentTable:boolean = true;
  isUibFlow: boolean = false;
  isWhatsappFlow: boolean = false;
  totalSecondPage =0;
  dateFormat: any;
  screenHeight: number;
  itemsPerPage: number;
  selectedRow : any;
  isWhatsAppAvailable: boolean = false; 
  isAllFlows: boolean = false;
  selectedFlowId: any;
  constructor(private translate: TranslateService, private injector: Injector, private modalService: NgbModal,
    public flowResponseService: FlowResponsesService, public router:Router
  ) {
    this.translatorLanguage = localStorage.getItem('Language');
    this.translate.setDefaultLang('en');
    this.translate.use(this.translatorLanguage ? this.translatorLanguage : 'en');
    this.sharedService = this.injector.get<SharedsService>(SharedsService);
}
  ngOnInit() {
  this.dateFormat = JSON.parse(localStorage.getItem('user'))?.dateFormat
      ? JSON.parse(localStorage.getItem('user')).dateFormat
      : 'MM/dd/YYYY';

      this.screenHeight = window.innerHeight;
      this.itemsPerPage = this.getScreenHeightValue(this.screenHeight);

      const args = {
        search: '',
        limit: this.itemsPerPage? this.itemsPerPage : 10,
        skip: this.itemsPerPage? this.pagelist * this.itemsPerPage - this.itemsPerPage : 0,
      };
    this.getAllFlowList(this.flowInfo, args);
  
  }


  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenHeight = window.innerHeight;
    this.itemsPerPage = this.getScreenHeightValue(this.screenHeight);
    const args = {
      search: '',
      limit: this.itemsPerPage? this.itemsPerPage : 10,
      skip: this.itemsPerPage? this.pagelist * this.itemsPerPage - this.itemsPerPage : 0,
      flow_id: this.selectedFlowId ? this.selectedFlowId : ''
    };

    if(this.showParentTable === true){
      this.getAllFlowList(this.flowInfo, args);
    }
    else {
      this.fetchUibFlowsDetails(this.selectedRow,args);
    } 
  }

  getScreenHeightValue(h): number {
    return h > 1700 ? 40 : Math.max(Math.round((h * 4) / 300), 10);
  }


getAllFlowList(flowData: any, args?: any): void {
  this.loaderShow = true;
  this.flowInfo = flowData;

 this.totalFlows = 0;
  if (flowData === 'all') {
    this.fetchFlowListAll(args);
    this.flowNames = this.dataOne; 
     this.selectedFlowName = null;
  } else if (flowData === 'UIB') {
    this.pagelist = 1;
    this.fetchFlows({ flowtype: 'UIB', ...args });
    this.selectedFlowName = null;
  } 
  else {
    this.pagelist = 1;
    this.fetchFlows({ flowtype: 'WhatsApp', ...args });
    this.selectedFlowName = null;
  }
}

async fetchFlows(flowData: any): Promise<void> {
 const args = {
    search: this.searchQuery?.trim() || '',
    limit: flowData.limit || this.itemsPerPage,
    skip: flowData.skip || this.pagelist * this.itemsPerPage - this.itemsPerPage,
    flowtype: flowData.flowtype,
  };

  this.loaderShow = true;
  this.dataOne = [];
 this.flowNames = [];
 this.totalFlows = 0;

  this.flowResponseService.apiFetchUibFlows(args).subscribe(
    (data: any) => {
      this.loaderShow = false;
      if (data.status === 200 && data?.info?.length) {
        this.dataOne = data.info.map((item: any) => ({
          flow_type: item.flow_type,
          flow_name: item.flow_name,
          created_on: item.created_on,
          Flowresponsecount: item.Flowresponsecount,
          flow_id: item._id,
        }));

        const firstFlow = this.dataOne[0];
         const tempFlow = firstFlow ? [{ flow_name: firstFlow.flow_name }] : [];

         this.flowNames = this.dataOne;
        this.selectedFlowName = firstFlow|| null;
        this.totalFlows = data?.count;
      }
    },
    (error: any) => {
      this.loaderShow = false;
      console.error('Error fetching flows:', error);
    }
  );
}


fetchFlowListAll(args: any): void {
let arg = {
  search: this.searchQuery? this.searchQuery?.trim() : '',
  limit: this.itemsPerPage? this.itemsPerPage : 10,
  skip: this.itemsPerPage? this.pagelist * this.itemsPerPage - this.itemsPerPage : 0,
};  

  this.dataOne = [];
  this.detailedDataTable= [];
  this.loaderShow = true;
  this.flowResponseService.apiFetchUibFlows(arg).subscribe(
    (data: any) => {
      this.loaderShow = false;
      if (data.status === 200 && data?.info?.length) {
        this.dataOne = data.info.map((item: any) => ({
          flow_type: item.flow_type,
          flow_name: item?.flow_name,
          created_on: item.created_on,
          Flowresponsecount: item.Flowresponsecount,
          flow_id: item._id,
        }));

        this.onflowcountall = data?.count;
      this.totalFlows = this.onflowcountall;

        this.dataOne.forEach(element => {
          if (element.flow_type == 'WhatsApp') {
            this.isWhatsAppAvailable = true;
        }
        })
      }
    },
    (error: any) => {
      this.loaderShow = false;
      console.error('Error fetching all flow lists:', error);
    }
  );
}

  openUserDetails(user: any, content: any): void {
    this.selectedUser = user;
    this.modalService.open(content, { size: 'lg', backdrop: 'static' });
}
 
  newClick(row:any) {
    this.parent = (!this.parent);
    this.detailedDataTable = []
    if(this.parent){
      this.totalFlows = 0;
      this.detailedDataTable.push(row)
    }
  }
  handleGoBack() {
    this.goBack = true;
    this.showParentTable = true;
    this.totalSecondPage = 0;
    this.pagelist = 1;
    if(this.isAllFlows){
      this.flowInfo = 'all';
    }
    this.getAllFlowList(this.flowInfo)
  }


  fetchUibFlowsDetails(row: any, args: any) {
    if (!args) {
      args = {
        search: '',
        limit: this.itemsPerPage ? this.itemsPerPage : 10,
        skip: this.itemsPerPage ? this.pagelist * this.itemsPerPage - this.itemsPerPage : 0,
        flow_id: row._id,
        flowname: row.flow_name
      };
    }

    if (!args.flow_id) {
      args.flow_id = row?._id || '';
    }

    this.detailedDataTable = [];
    this.flowResponseService.apiFetchUibFlowsDetails(args).subscribe(
      (data: any) => {
        this.loaderShow = false;
        if (data.status === 200) {
          if ( data?.info?.length) {
            const sortedData = data.info.sort(
              (a: any, b: any) => new Date(b.modified_on).getTime() - new Date(a.modified_on).getTime()
            );
    
            this.detailedDataTable = sortedData.map((item: any) => {
              const questions: Record<string, any> = {};
              if (item?.flow_responses && typeof item.flow_responses === 'object') {
                Object.entries(item.flow_responses).forEach(([key, value]: [string, any]) => {
                  if (value && typeof value === 'object' && value.answer) {
                    questions[value.question] = value.answer;
                  } else {
                    questions[key] = value;
                  }
                });
              }
              delete questions.flow_token; 

              return {
                flow_type: item.flow_type,
                flow_name: item.flow_name,
                flow_id: item.flow_id,
                username: item.username,
                user_identifier: item.user_identifier,
                modified_on: item.modified_on,
                questions, 
              };
            });
  
            
            this.totalSecondPage = this.onflowresponsecount;
          }else {
            this.detailedDataTable = [];
          }
        } 
        
      },
      (error: any) => {
        console.error("Error fetching UIB flows: ", error);
      }
    );
  }


  routeToFlows(event: any, row: any) {

    this.isAllFlows = this.flowInfo === 'all';

    this.goBack = false;
    this.showParentTable = false;

    if (!this.showParentTable) {
        this.totalSecondPage = row.Flowresponsecount;
        this.onflowresponsecount = row.Flowresponsecount;
        this.selectedFlowName = row; 
        this.selectedFlowId = row.flow_id;               
        this.flowNames = this.dataOne; 
        this.selectedRow = row;                      

        const args = {
            search: '',
            limit: this.itemsPerPage || 10,
            skip: this.itemsPerPage ? this.pagelist * this.itemsPerPage - this.itemsPerPage : 0,
            flowname: this.selectedFlowName.flow_name,
            flow_id: this.selectedFlowId,
        };
        this.fetchUibFlowsDetails(this.selectedFlowId, args);

        
        this.flowInfo = row.flow_type;
    }
}



onFlowNameChange(selectedFlow: any) {
  if (selectedFlow?.value?.flow_id) {
    //this.pagelist = 1; 
    this.totalSecondPage = 0;
    this.onflowresponsecount = selectedFlow.value.Flowresponsecount;
    this.selectedFlowId = selectedFlow.value.flow_id;
    const args = {
      search: '',
      limit: this.itemsPerPage || 10,
      skip: this.itemsPerPage ? this.pagelist * this.itemsPerPage - this.itemsPerPage : 0,
      flow_id: this.selectedFlowId
    };

    this.fetchUibFlowsDetails(this.selectedFlowId, args);
  } else {
    console.error('Selected flow data is invalid:', selectedFlow);
  }
}
  
  pageChange(page: number) {
    this.pagelist = page;
    const args = {
      search: '',
      limit: this.itemsPerPage ? this.itemsPerPage : 10,
      skip: this.itemsPerPage ? this.pagelist * this.itemsPerPage - this.itemsPerPage : 0,
    };

    this.getAllFlowList(this.flowInfo,args);
  }
  

  pageChangeSecond(page: number) {
    this.pagelist = page;
  
    const args = {
      search: '',
      limit: this.itemsPerPage ? this.itemsPerPage : 10,
      skip: this.itemsPerPage ? this.pagelist * this.itemsPerPage - this.itemsPerPage : 0,
      flow_id: this.selectedFlowId || '',
    };
    this.fetchUibFlowsDetails(this.selectedFlowId, args);
  }

  downloadFlows() {
    if (!this.detailedDataTable || !this.detailedDataTable.length) {
      console.error('No data available to download.');
      return;
    }
  
    const args = {
      flowname: this.detailedDataTable[0].flow_name,
      flow_id: this.detailedDataTable[0].flow_id,
      flowtype: this.detailedDataTable[0].flow_type,
    };
  
    this.loaderShow = true;
    this.flowResponseService.apidownloadFlows(args).subscribe(
      (data: any) => {
        this.loaderShow = false;
        if (data?.status === 200 && data?.info?.length) {
          const sortedData = data.info.sort(
            (a: any, b: any) => new Date(b.modified_on).getTime() - new Date(a.modified_on).getTime()
          );
  
          const csvData = sortedData.map((item: any) => {
            const baseData = {
              'Flow Name': item?.flow_name || '',
              'Flow Type': item?.flow_type === 'UIB' ? 'Guided Flow' : item?.flow_type === 'WhatsApp' ? 'WhatsApp Flow' : item?.flow_type || '',
              'User Name': item?.username || '',
              'User Identifier': item?.user_identifier || '',
              'Submitted on': item?.modified_on
                ? `${new Date(item.modified_on).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })} ${new Date(item.modified_on).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })}`
                : '',
            };
            let questionsAndAnswers: any = {};
            if (item?.flow_responses) {
              const responseInfo = JSON.parse(item?.flow_responses);
              delete responseInfo.flow_token;
              if (args.flowtype === 'UIB') {
                Object.entries(responseInfo).forEach(([key, value]: [string, any]) => {
                  if (value && typeof value === 'object' && value.answer) {
                    const cleanQuestion = (value.question || '').replace(/<br>/g, '').trim();
                    questionsAndAnswers[cleanQuestion] = value.answer || '';
                  }
                });
              } else if (args.flowtype === 'WhatsApp') {
                Object.entries(responseInfo).forEach(([key, value]: [string, any]) => {
                  const cleanKey = key.replace(/<br>/g, '').trim();
                  questionsAndAnswers[cleanKey] = value || '';
                });
              }
            }
  
            return { ...baseData, ...questionsAndAnswers };
          });
  
          const uniqueQuestions = Array.from(
            new Set(sortedData.flatMap((item: any) => {
              if (item?.flow_responses) {
                const responseInfo = JSON.parse(item?.flow_responses);
                delete responseInfo.flow_token;
                return Object.values(responseInfo)
                  .filter((response: any) => response && typeof response === 'object' && response.question)
                  .map((response: any) => response.question.replace(/<br>/g, '').trim());
              }
              return [];
            }))
          );
  
          const finalCsvData = csvData.map((row: any) => {
            uniqueQuestions.forEach((question: string) => {
              if (!row.hasOwnProperty(question)) {
                row[question] = '';
              }
            });
            return row;
          });

          let filename = finalCsvData[0]?.['Flow Name'] || 'flow_data'; 

          filename = filename.replace(/\s+/g, '_'); 

          alasql(`SELECT * INTO XLSX("${filename}.xlsx", {headers: true}) FROM ?`, [finalCsvData]);
  
          console.log('Final data for export:', finalCsvData);
        } else {
          console.error('No data received for download.');
        }
      },
      (error: any) => {
        this.loaderShow = false;
        console.error('Error occurred while downloading flows:', error);
      }
    );
  }

  onSearchChange(query: string): void {
    this.searchQuery = query;
    this.pagelist = 1; 
    this.triggerSearch();
  }

  triggerSearch(): void {
    const args = {
      search: this.searchQuery.trim(),
      limit: this.itemsPerPage,
      skip: this.pagelist * this.itemsPerPage - this.itemsPerPage,
      flow_id: this.selectedFlowId ? this.selectedFlowId : ''
    };
    if (this.showParentTable) {
      this.getAllFlowList(this.flowInfo, args);
    } else {
      this.fetchUibFlowsDetails(this.selectedRow, args);
    }
  }
  

  onRowKeydown(event: KeyboardEvent, row: any, userDetailsModal: any): void {
    if (event.key === 'Enter' || event.key === ' ') {
      this.openUserDetails(row, userDetailsModal); 
      event.preventDefault(); 
    }
  }
  
}