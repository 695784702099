import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../login/login.service';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from 'src/app/loader/loader.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  resetKey: string;
  submitted = false;
  hideSetPasswordDialogBox = false;
  showLoader = true;
  isUserValid = false;
  errorMessage = '';
  error = false;
  translatorLanguage: any;
  userData: {};
  constructor(
    private readonly router: Router,
    private readonly currentRoute: ActivatedRoute,
    private readonly toastr: ToastrService,
    private readonly loginService: LoginService,
    private readonly translate: TranslateService,
    private readonly loader: LoaderService,
  ) {
    this.translatorLanguage = localStorage.getItem('Language');
    this.translate.setDefaultLang('en');
    this.translate.use(this.translatorLanguage ? this.translatorLanguage : 'en');
  }

  ngOnInit(): void {
    this.loginService.logout();
    this.resetKey = this.currentRoute.snapshot.queryParamMap.get('key');
    this.loginService
      .onLoadSignup(this.resetKey)
      .pipe(first())
      .subscribe({
        next: (data) => {
          if (data.status !== 200) {
            this.errorMessage = data.info;
            this.loader.display(false);
            this.toastr.error('', this.errorMessage);
            this.router.navigateByUrl('/login');
          } else {
            this.isUserValid = true;
            this.toastr.success('', 'Your email has been successfully verified. Your account is now fully activated.');
            this.router.navigateByUrl('/login');
          }
        },
        error: (err) => {
          this.toastr.error('', this.translate.instant('toastr.error_email'));
          this.loader.display(false);
          this.router.navigateByUrl('/login');
        }
  });
  }
}
