import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from 'src/app/loader/loader.service';
import { SharedsService } from 'src/app/shareds/shareds.service';
import { LoginService } from '../login/login.service';
import { ToastrService } from 'ngx-toastr';
import { Stripe, StripeElements, StripeCardElement } from '@stripe/stripe-js';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Helpers } from '../home/users/helper';
import { Router } from '@angular/router';

@Component({
  selector: 'app-self-service-onboard',
  templateUrl: './self-service-onboard.component.html',
  styleUrls: ['./self-service-onboard.component.scss']
})
export class SelfServiceOnboardComponent implements OnInit {
  @Input() validuser = {
    isUserValid: false
  };
  signUpForm: FormGroup;
  sharedService: SharedsService;
  loader: LoaderService;
  loginService: LoginService;
  translate: TranslateService;
  defaultLanguage: any = 'en';
  submitted: boolean;
  countriesList = [];
  config = {
    displayKey: 'name',
    search: true,
    height: '175px',
    placeholder: 'Select',
    limitTo: this.countriesList.length,
    moreText: 'more',
    noResultsFound: 'No results found!',
    searchPlaceholder: 'Search',
    searchOnKey: 'name',
  };
  choosePlan = false;
  email = '';
  planList = [];
  choosenPlan: any;
  stripe: Stripe | null = null;
  elements: StripeElements | null = null;
  cardElement: StripeCardElement | null = null;
  formBuilder: UntypedFormBuilder;
  selectedSignupOption = 'self';
  @Output() dataEmitter = new EventEmitter<boolean>();
  loadershow = false;
  showPassword: boolean = false;
  constructor(
    private readonly fb: FormBuilder,
    private readonly injector: Injector,
    private readonly toastr: ToastrService,
    private readonly deviceService: DeviceDetectorService,
    private readonly router: Router,
  ) {
    this.sharedService = this.injector.get<SharedsService>(SharedsService);
    this.loader = this.injector.get<LoaderService>(LoaderService);
    this.loginService = this.injector.get<LoginService>(LoginService);
    this.translate = this.injector.get<TranslateService>(TranslateService);
    this.translate.setDefaultLang('en');
    this.translate.use(this.defaultLanguage ? this.defaultLanguage : 'en');
    this.formBuilder = this.injector.get<UntypedFormBuilder>(UntypedFormBuilder);
    this.signUpForm = this.formBuilder.group(
      {
        name: new FormControl('', [Validators.required]),
        companyName: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.email]),
        password: new FormControl('', [Validators.required, Validators.minLength(6)]),
        city: new FormControl('', [Validators.required]),
        zip: new FormControl('', [Validators.required]),
        country: new FormControl('', [Validators.required]),
      },
      {
        validator: [Helpers.passwordValidator],
      }
    );
  }
  ngOnInit() {
    this.fetchCountries();
    this.listPlan();
    if(this.validuser?.['isUserValid']) {
      this.signUpForm.patchValue({
        email: this.validuser?.['email'] || 'user@example.com',
      });
    }
  }
  onSubmit() {
    this.submitted = true;
    if (!this.signUpForm.invalid) {
      this.choosePlan = true;
    }
    this.dataEmitter.emit(true);
  }
  get f() { return this.signUpForm.controls; }

  fetchCountries(): void {
    this.loginService.getCountries().subscribe({
      next: (data) => {
        this.countriesList = data?.['info']?.sort((a, b) => a?.name.localeCompare(b?.name));
        if(data.ipCountryCode) {
          const country = this.countriesList.find(item=> item.code.toLowerCase() === data.ipCountryCode.toLowerCase())
          this.signUpForm.patchValue({
            country: country || ''
          });
        }
      },
      error: (err) => {
        console.error('Error fetching countries:', err);
      },
    });
  }
  mofifyMenuPlanList() {
    this.planList.forEach(item => {
      item['tabs'] = [];
      item.details?.menu?.forEach(tab => {
        const subMenu = tab.subMenu;
        subMenu?.forEach(sub => item['tabs'].push(sub));
      })
    })
  }
  onPlanChoose(plan) {
    this.choosenPlan = plan;
    this.loader.display(true);
    this.loginService.initializeStripe();
    if(plan?.name?.toLowerCase() !== 'premium') {
      this.selfOnboardApi();
  }
}
  listPlan() {
    this.loginService.getPlanDetails().subscribe({
      next: (data) => {
        if (data.info) {
          this.planList = data.info;
        }
        this.mofifyMenuPlanList();
      },
      error: (err) => {
        console.error('Error listPlan:', err);
      },
    });
  }
  selfOnboardApi() {
    const browser_details  = this.deviceService.getDeviceInfo();
    const arg = {
      planId: this.choosenPlan._id,
      name: this.signUpForm?.value?.name.trim(),
      country: this.signUpForm?.value?.country?.name,
      email: this.signUpForm?.value?.email.trim(),
      password: this.signUpForm?.value?.password,
      companyName: this.signUpForm?.value?.companyName.trim(),
      zip: this.signUpForm?.value?.zip,
      city: this.signUpForm?.value?.city,
      browser_details : browser_details
    }
    arg['priceId'] = this.choosenPlan.priceId;
    this.loginService.createCheckoutSession(arg).subscribe(async (response) => {
      if (response?.info?.['sessionId']) {
        this.loader.display(false);
        await this.loginService.redirectToCheckout(response.info['sessionId']);
      } else {
        this.loader.display(false);
        this.toastr.error(response.info);
        this.choosePlan = false;
      }
    });
  }
  selectedSignupChange(value) {
    this.selectedSignupOption = value;
  }
  submitEmailValidation() {
    this.submitted = true;
    if(!this.email) {
      return;
    } else {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      const validationResult = emailRegex.test(this.email) ? null : true;
      if(validationResult) {
        this.toastr.error(
          this.translate.instant('login.must_be_valid')
        );
        return;
      }
    }
    this.loadershow = true;
    this.loginService.signUpEmailValidate(this.email.trim()).subscribe({
      next: (data) => {
        if(data.status === 200) {
          this.toastr.info('', data.info);
          this.dataEmitter.emit(false);
        } else {
          this.toastr.error('', data.info);
          this.email = '';
          this.submitted = false;
        }
        this.loadershow = false;
      },
      error: (err) => {
        console.error('Error listPlan:', err);
      },
    });
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }
}
